export const useSetRefreshTokenExpiration = () => {
  const refreshTokenExpiresAt = useCookie<number>("refresh_exp", {
    path: "/",
    maxAge: 60 * 60 * 24 * 30,
  });

  return (refreshTokenExpiresIn: number) => {
    refreshTokenExpiresAt.value =
      new Date().getTime() + refreshTokenExpiresIn * 1000;
  };
};
