import dayjs from "dayjs";

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) return;

  const { $performTokenRefresh } = useNuxtApp();

  const refreshToken = useCookie<string>("refresh_token").value;
  const tokenExpTimestamp = useCookie<number>("token_exp").value;

  if (refreshToken && dayjs().isAfter(tokenExpTimestamp)) {
    $performTokenRefresh();
  }
});
