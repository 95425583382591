export const makeAuthHeader = (): Record<string, string> => {
  const token = useCookie("token").value;
  const accountId = useCookie("account").value;

  if (token && accountId) {
    return {
      Authorization: `${token}`,
      "x-account-id": accountId,
    };
  }
  return {};
};
