import { stringToBoolean } from "~/utils/string/string-to-boolean";

export const useIsCrowdfunding = () => {
  const url = useRequestURL();
  const env = useRuntimeConfig();

  const publicOfferEnv = !stringToBoolean(env.public.isCrowdfunding);
  const publicOfferUrl = url.hostname.includes("investe");

  return !(publicOfferEnv || publicOfferUrl);
};
