import { useLogSentryInfo } from "./useLogSentryInfo";

export const useSendClientErrorToSentry = () => {
  const logSentryInfo = useLogSentryInfo();

  return async (error: any) => {
    const VueSentry = await import("@sentry/vue");
    logSentryInfo();
    VueSentry.captureException(error);
  };
};
