import type { I18nKey } from "~/i18n/types";

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp();

  const typedText = (key: I18nKey, opts?: any) => {
    return $i18n.t(key, opts);
  };

  return { provide: { tt: typedText } };
});
