import { defineStore } from "pinia";
import { useRawCookie } from "@/composables/useRawCookie";
import { localStorageSet } from "@/utils/internet/local-storage";
import { login } from "~/services/auth";
import { useSetRefreshTokenExpiration } from "~/composables/useSetRefreshTokenExpiration";
import { useSetTokenExpiration } from "~/composables/useSetTokenExpiration";

export interface Account {
  shortId: string;
  nickName: string;
  device: string;
  ip: string;
  createdAt: string;
  nid: string;
  source: string;
  languageId: string;
  email: string;
  fullName: string;
  updatedAt: string;
  confirmedMfa: string;
  id: string;
  phone: string;
}

interface SignInRequest {
  nid: string;
  password: string;
}

export interface SignInResponse {
  token: string;
  refresh_token: string;
  exp: number;
  account_id: string;
  account: Account;
}

const THIRTY_DAYS = 60 * 60 * 24 * 30;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null as SignInResponse | null,
    loading: false,
    authenticated: false,
  }),
  getters: {
    isAuthenticated: (state) => state.authenticated,
    isLoading: (state) => state.loading,
  },
  actions: {
    async signIn({ nid, password }: SignInRequest) {
      const setRefreshTokenExpiration = useSetRefreshTokenExpiration();
      const setTokenExpiration = useSetTokenExpiration();
      this.loading = true;

      try {
        const httpResponse = await login({ nid, password });
        const data = httpResponse;

        localStorageSet("HCP-AUTH", {
          isAuth: true,
          exp: data.expiresIn,
          refresh_token: data.refreshToken,
          token: data.accessToken,
          account_id: data.account.id,
          account: data.account,
        });

        this.authenticated = true;

        const tokenCookie = useCookie("token", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });
        tokenCookie.value = data.accessToken;

        const refreshTokenCookie = useCookie("refresh_token", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });

        refreshTokenCookie.value = data.refreshToken;

        setRefreshTokenExpiration(data.refreshTokenExpiresIn);
        setTokenExpiration(data.accessTokenExpiresIn);

        const accountCookie = useCookie("account", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });

        accountCookie.value = data.account.id;

        const nidCookie = useRawCookie("nid", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });

        nidCookie.value = data.account.nid;

        return data;
      } catch (error) {
        return null;
      } finally {
        this.loading = false;
      }
    },
    signOut() {
      this.setAuthenticated(false);

      const tokenCookie = useCookie("token");
      tokenCookie.value = null;

      const refreshTokenCookie = useCookie("refresh_token");
      refreshTokenCookie.value = null;

      const accountCookie = useCookie("account");
      accountCookie.value = null;

      const nidCookie = useRawCookie("nid");
      nidCookie.value = null;

      const refreshExpCookie = useCookie("refresh_exp");
      refreshExpCookie.value = null;

      const tokenExpCookie = useCookie("token_exp");
      tokenExpCookie.value = null;
    },
    setAuthenticated(IsAuthenticated: boolean) {
      this.authenticated = IsAuthenticated;
    },
  },
});
