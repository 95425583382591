import type { NitroFetchOptions } from "nitropack";
import { makeAuthHeader } from "@/utils/auth/make-auth-header";
import { UnauthenticatedError, getDomainError } from "~/composables/api/errors";
import { useIsCrowdfunding } from "~/composables/useIsCrowdfunding";
import { useSendClientErrorToSentry } from "~/composables/useSendClientErrorToSentry";

const makeCorsHeader = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, GET, POST, PATCH, PUT, OPTIONS",
  "X-Content-Type-Options": "nosniff",
});

const makeSecurityHeader = () => ({
  "Content-Security-Policy": "default-src https frame-ancestors 'none'",
  "Referrer-Policy": "same-origin",
  "X-Frame-Options": "DENY",
  "X-XSS-Protection": "1; mode=block",
  "X-Is-Webapp": "true",
});

const THIRTY_DAYS = 60 * 60 * 24 * 30;

export default defineNuxtPlugin(() => {
  const { $performTokenRefresh, $refreshLock } = useNuxtApp();
  const isCrowdfunding = useIsCrowdfunding();
  const env = useRuntimeConfig();
  const cookieOptions = { path: "/", maxAge: THIRTY_DAYS };
  const tokenCookie = useCookie("token", cookieOptions);
  const signOut = useSignOut();

  const sendErrorToSentry = useSendClientErrorToSentry();

  const getDefaultHeaders = () => {
    return {
      ...makeCorsHeader(),
      ...makeSecurityHeader(),
      ...makeAuthHeader(),
      ...(isCrowdfunding ? { "x-website-type": "crowdfunding" } : {}),
    };
  };

  const createFetchClient = ({
    baseUrl,
    headers,
  }: {
    baseUrl: string;
    headers: any;
  }) => {
    const wrappedFetch = async <T>(
      path: string,
      options: NitroFetchOptions<any> = {},
    ) => {
      try {
        return await $fetch<T>(path, {
          ...options,
          headers: {
            ...options.headers,
            ...(tokenCookie.value && { Authorization: tokenCookie.value }),
          },
        });
      } catch (error) {
        throw getDomainError(error);
      }
    };

    const makeRequest = async <T>(
      path: string,
      options: NitroFetchOptions<any> = {},
    ) => {
      try {
        await $refreshLock();
        return await (<T>wrappedFetch(path, {
          ...options,
          baseURL: baseUrl,
          headers,
        }));
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          await $performTokenRefresh();

          try {
            return await (<T>wrappedFetch(path, {
              ...options,
              baseURL: baseUrl,
              headers,
            }));
          } catch (error) {
            sendErrorToSentry(error);
            if (error instanceof UnauthenticatedError) {
              signOut();
            }
            throw error;
          }
        }
        throw error;
      }
    };

    return makeRequest;
  };

  const api = createFetchClient({
    baseUrl: env.public.apiBaseURL as string,
    headers: getDefaultHeaders(),
  });
  const operationV2Api = createFetchClient({
    baseUrl: env.public.apiOperationV2BaseURL as string,
    headers: getDefaultHeaders(),
  });
  const authApi = createFetchClient({
    baseUrl: env.public.authBaseURL as string,
    headers: { "Content-Type": "application/json" },
  });

  return {
    provide: {
      api,
      operationV2Api,
      authApi,
    },
  };
});
