export const localStorageSet = (key: string, value: object): void => {
  if (typeof window === "undefined") return;

  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
};

export const localStorageGet = <T>(key: string): T | null => {
  if (typeof window === "undefined") return null;

  const item = localStorage.getItem(key);
  return item ? (JSON.parse(item) as T) : null;
};
