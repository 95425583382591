import { useAuthStore } from "@/store/auth";

export const useSignOut = () => {
  const auth = useAuthStore();
  const router = useRouter();

  return () => {
    const route = router.currentRoute.value;
    const mustRedirectToLanding =
      route.fullPath.startsWith("/painel") ||
      route.fullPath.startsWith("/completar-cadastro") ||
      route.meta.auth;

    const mustRedirectToFdiAuth =
      route.name === "FdiValue" ||
      route.name === "FdiConfirmation" ||
      route.name === "FdiEquity";

    auth.signOut();

    if (mustRedirectToFdiAuth) {
      return navigateTo({
        name: "FdiAuth",
        params: { operationId: route.params.operationId },
      });
    } else if (mustRedirectToLanding) {
      return navigateTo("/");
    }
  };
};
