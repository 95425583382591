import {
  TransactionNotValidError,
  UnauthenticatedError,
} from "~/composables/api/errors";
import { useSendClientErrorToSentry } from "~/composables/useSendClientErrorToSentry";

interface GraphQLError {
  extensions?: {
    code?: string;
    originalError?: any;
  };
  locations?: {
    column?: number;
    line?: number;
  }[];
  message?: string;
  path?: string[];
}

interface GraphqlResponse<T> {
  data?: T;
  errors?: GraphQLError[];
}

const getGraphQLError = (errors: GraphQLError[] | undefined) => {
  const code = errors?.[0]?.extensions?.code;

  switch (code) {
    case "UNAUTHENTICATED":
      return new UnauthenticatedError();
    case "HCP__TRANSACTION_STATUS_INVALID":
      return new TransactionNotValidError();
    default:
      return new Error(`Unexpected GraphQL error ${code}`);
  }
};

export default defineNuxtPlugin(() => {
  const env = useRuntimeConfig();
  const signOut = useSignOut();
  const { $performTokenRefresh, $refreshLock, $token } = useNuxtApp();

  const sendErrorToSentry = useSendClientErrorToSentry();

  const request = async <T>(query: string, variables?: any): Promise<T> => {
    const result = await $fetch<GraphqlResponse<T>>(env.public.graphqlBaseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...($token.value && { Authorization: $token.value }),
      },
      body: JSON.stringify({ query, variables }),
      ignoreResponseError: true,
    });

    if (result.errors?.length || !result.data) {
      throw getGraphQLError(result.errors);
    }

    return result.data;
  };

  const updateAuthCookies = async () => {
    try {
      await $performTokenRefresh();
    } catch (error) {
      sendErrorToSentry(error);
      signOut();
      throw new UnauthenticatedError();
    }
  };

  const gqlSecondAttempt = async <ResponseType = any>(
    query: string,
    variables?: any,
  ): Promise<ResponseType> => {
    try {
      await $refreshLock();
      const res = await request<ResponseType>(query, variables);
      return res;
    } catch (error) {
      sendErrorToSentry(error);

      if (error instanceof UnauthenticatedError) {
        signOut();
      }
      throw new UnauthenticatedError();
    }
  };

  const gqlRequestWithAuth = async <ResponseType = any>(
    query: string,
    variables?: any,
  ): Promise<ResponseType> => {
    try {
      await $refreshLock();
      const res = await request<ResponseType>(query, variables);
      return res;
    } catch (error) {
      sendErrorToSentry(error);
      if (error instanceof UnauthenticatedError) {
        await updateAuthCookies();
        const res = await gqlSecondAttempt(query, variables);
        return res;
      }

      throw error;
    }
  };

  return {
    provide: {
      gql: gqlRequestWithAuth,
    },
  };
});
