export const useSetTokenExpiration = () => {
  const tokenExpiresAt = useCookie<number>("token_exp", {
    path: "/",
    maxAge: 60 * 60 * 24 * 30,
  });

  return (tokenExpiresIn: number) => {
    tokenExpiresAt.value = new Date().getTime() + tokenExpiresIn * 1000;
  };
};
