/* eslint-disable import/no-named-as-default-member */
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

export default defineNuxtPlugin(() => {
  dayjs.extend(utc);
  dayjs.extend(updateLocale);
  dayjs.locale("pt-br");

  dayjs.updateLocale("pt-br", {
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
  });
});
