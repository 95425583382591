import { default as indexTbXDWK8eQ9Meta } from "/vercel/path0/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancarias2Cj20KcQOMeta } from "/vercel/path0/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documento9N6LAZPHQ3Meta } from "/vercel/path0/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniaisyKDJDiWeoxMeta } from "/vercel/path0/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoesSfUt0zR7RjMeta } from "/vercel/path0/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentos2Wb3bjJmE5Meta } from "/vercel/path0/pages/completar-cadastro/documentos.vue?macro=true";
import { default as enderecoRH3OPmLX5bMeta } from "/vercel/path0/pages/completar-cadastro/endereco.vue?macro=true";
import { default as index86DyjHJUcRMeta } from "/vercel/path0/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoaisFrjlULOZmjMeta } from "/vercel/path0/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93iMbdBCyiWqMeta } from "/vercel/path0/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as _91slug_93RHrOUbVTVHMeta } from "/vercel/path0/pages/crie-sua-conta/b2b/[slug].vue?macro=true";
import { default as _91slug_9320GULkO4OkMeta } from "/vercel/path0/pages/crie-sua-conta/convite/[slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as confirmacao70Yjj3YzNBMeta } from "/vercel/path0/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhesStrVcJdZlsMeta } from "/vercel/path0/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoQJEQ0X0Sy4Meta } from "/vercel/path0/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as index9byNyU8QEBMeta } from "/vercel/path0/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contah1Ik4YH8FOMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contaSkDQ0tQphHMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as index4OzrzJy2oHMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as inicionUWCXNIOl5Meta } from "/vercel/path0/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93aysZWVdVIcMeta } from "/vercel/path0/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_93EBtug1RzAtMeta } from "/vercel/path0/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadasrSqUoNhD9JMeta } from "/vercel/path0/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoFExxblQTIIMeta } from "/vercel/path0/pages/oportunidades/historico.vue?macro=true";
import { default as index6E1pbgfjLrMeta } from "/vercel/path0/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93Z4obi6RG6VMeta } from "/vercel/path0/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as indexB112Rdsc1eMeta } from "/vercel/path0/pages/originadoras/index.vue?macro=true";
import { default as _91slug_93tOIOCumazRMeta } from "/vercel/path0/pages/painel/ajuda/[slug].vue?macro=true";
import { default as indexlIaHMICzvpMeta } from "/vercel/path0/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93ohfKyvzqhfMeta } from "/vercel/path0/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as index4zvfyrDpQuMeta } from "/vercel/path0/pages/painel/index.vue?macro=true";
import { default as indexXyMqzEaGQPMeta } from "/vercel/path0/pages/painel/indicacao/index.vue?macro=true";
import { default as indexjriJGez7d4Meta } from "/vercel/path0/pages/painel/ir/index.vue?macro=true";
import { default as indexoRFjcRdhR3Meta } from "/vercel/path0/pages/painel/pendentes/index.vue?macro=true";
import { default as index4ge3cW3RvRMeta } from "/vercel/path0/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as indextJn3HW0S6ZMeta } from "/vercel/path0/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as index51TLsa3RqnMeta } from "/vercel/path0/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionar5DbZF45VAkMeta } from "/vercel/path0/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexcNoZLf9kikMeta } from "/vercel/path0/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editarBlm77FzKTnMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionarv4gx5QDWNTMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexSS8G5cbybdMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as index6PTCbwI1a2Meta } from "/vercel/path0/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as index0iybtMw4bpMeta } from "/vercel/path0/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexk4WHrAo1f1Meta } from "/vercel/path0/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as index9DLGyTJwaDMeta } from "/vercel/path0/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as indexOCaA4S2QoKMeta } from "/vercel/path0/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexnjmxEyuke3Meta } from "/vercel/path0/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93GUBCS5HFzHMeta } from "/vercel/path0/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as depositoVEnQ0vDnLTMeta } from "/vercel/path0/pages/painel/wallet/deposito.vue?macro=true";
import { default as indexkGK62At1OMMeta } from "/vercel/path0/pages/painel/wallet/index.vue?macro=true";
import { default as resgateuqCnhvWZRDMeta } from "/vercel/path0/pages/painel/wallet/resgate.vue?macro=true";
import { default as indexQpXVhpZ6xmMeta } from "/vercel/path0/pages/parceiros/index.vue?macro=true";
import { default as indexW75ZCJS0M5Meta } from "/vercel/path0/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexbe0Pcqyc0GMeta } from "/vercel/path0/pages/quem-somos/index.vue?macro=true";
import { default as index2zCys330d0Meta } from "/vercel/path0/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_93WVrBrc7ffnMeta } from "/vercel/path0/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexzay5PkV141Meta } from "/vercel/path0/pages/termos-de-uso/index.vue?macro=true";
import { default as indexP3dhkR4w1VMeta } from "/vercel/path0/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: "cases-de-sucesso",
    path: "/cases-de-sucesso",
    component: () => import("/vercel/path0/pages/cases-de-sucesso/index.vue")
  },
  {
    name: "completar-cadastro-conta-bancaria",
    path: "/completar-cadastro/conta-bancaria",
    meta: conta_45bancarias2Cj20KcQOMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/conta-bancaria.vue")
  },
  {
    name: "completar-cadastro-dados-documento",
    path: "/completar-cadastro/dados-documento",
    meta: dados_45documento9N6LAZPHQ3Meta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/dados-documento.vue")
  },
  {
    name: "completar-cadastro-dados-patrimoniais",
    path: "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniaisyKDJDiWeoxMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/dados-patrimoniais.vue")
  },
  {
    name: "completar-cadastro-declaracoes",
    path: "/completar-cadastro/declaracoes",
    meta: declaracoesSfUt0zR7RjMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/declaracoes.vue")
  },
  {
    name: "completar-cadastro-documentos",
    path: "/completar-cadastro/documentos",
    meta: documentos2Wb3bjJmE5Meta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/documentos.vue")
  },
  {
    name: "completar-cadastro-endereco",
    path: "/completar-cadastro/endereco",
    meta: enderecoRH3OPmLX5bMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/endereco.vue")
  },
  {
    name: "completar-cadastro",
    path: "/completar-cadastro",
    meta: index86DyjHJUcRMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/index.vue")
  },
  {
    name: "completar-cadastro-informacoes-pessoais",
    path: "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoaisFrjlULOZmjMeta || {},
    component: () => import("/vercel/path0/pages/completar-cadastro/informacoes-pessoais.vue")
  },
  {
    name: "crie-sua-conta-partner",
    path: "/crie-sua-conta/:partner()",
    meta: _91partner_93iMbdBCyiWqMeta || {},
    component: () => import("/vercel/path0/pages/crie-sua-conta/[partner].vue")
  },
  {
    name: "crie-sua-conta-b2b-slug",
    path: "/crie-sua-conta/b2b/:slug()",
    meta: _91slug_93RHrOUbVTVHMeta || {},
    component: () => import("/vercel/path0/pages/crie-sua-conta/b2b/[slug].vue")
  },
  {
    name: "crie-sua-conta-convite-slug",
    path: "/crie-sua-conta/convite/:slug()",
    meta: _91slug_9320GULkO4OkMeta || {},
    component: () => import("/vercel/path0/pages/crie-sua-conta/convite/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "FdiConfirmation",
    path: "/investir/:operationId()/confirmacao",
    meta: confirmacao70Yjj3YzNBMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/confirmacao.vue")
  },
  {
    name: "FdiDetails",
    path: "/investir/:operationId()/detalhes",
    meta: detalhesStrVcJdZlsMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/detalhes.vue")
  },
  {
    name: "FdiEquity",
    path: "/investir/:operationId()/enquadramento",
    meta: enquadramentoQJEQ0X0Sy4Meta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/enquadramento.vue")
  },
  {
    name: "FdiValue",
    path: "/investir/:operationId()",
    meta: index9byNyU8QEBMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/index.vue")
  },
  {
    name: "FdiAuthRoot",
    path: "/investir/:operationId()/inicio",
    meta: inicionUWCXNIOl5Meta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio.vue"),
    children: [
  {
    name: "FdiCreateAccount",
    path: "confirmar-conta",
    meta: confirmar_45contah1Ik4YH8FOMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/confirmar-conta.vue")
  },
  {
    name: "FdiConfirmAccount",
    path: "criar-conta",
    meta: criar_45contaSkDQ0tQphHMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/criar-conta.vue")
  },
  {
    name: "FdiAuth",
    path: "",
    meta: index4OzrzJy2oHMeta || {},
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/index.vue")
  }
]
  },
  {
    name: "novo-investidor-indicationCode",
    path: "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93aysZWVdVIcMeta || {},
    component: () => import("/vercel/path0/pages/novo-investidor/[indicationCode].vue")
  },
  {
    name: "OpportunityDetails",
    path: "/oportunidades/:operationId()",
    meta: _91operationId_93EBtug1RzAtMeta || {},
    component: () => import("/vercel/path0/pages/oportunidades/[operationId].vue")
  },
  {
    name: "oportunidades-finalizadas",
    path: "/oportunidades/finalizadas",
    meta: finalizadasrSqUoNhD9JMeta || {},
    component: () => import("/vercel/path0/pages/oportunidades/finalizadas.vue")
  },
  {
    name: "oportunidades-historico",
    path: "/oportunidades/historico",
    meta: historicoFExxblQTIIMeta || {},
    component: () => import("/vercel/path0/pages/oportunidades/historico.vue")
  },
  {
    name: "oportunidades",
    path: "/oportunidades",
    meta: index6E1pbgfjLrMeta || {},
    component: () => import("/vercel/path0/pages/oportunidades/index.vue")
  },
  {
    name: "PartnerOpportnityDetails",
    path: "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93Z4obi6RG6VMeta || {},
    component: () => import("/vercel/path0/pages/oportunidades/parceiro/[operationId].vue")
  },
  {
    name: "originadoras",
    path: "/originadoras",
    component: () => import("/vercel/path0/pages/originadoras/index.vue")
  },
  {
    name: "painel-ajuda-slug",
    path: "/painel/ajuda/:slug()",
    meta: _91slug_93tOIOCumazRMeta || {},
    component: () => import("/vercel/path0/pages/painel/ajuda/[slug].vue")
  },
  {
    name: "painel-ajuda",
    path: "/painel/ajuda",
    meta: indexlIaHMICzvpMeta || {},
    component: () => import("/vercel/path0/pages/painel/ajuda/index.vue")
  },
  {
    name: "painel-detalhes-investimento-operationId",
    path: "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93ohfKyvzqhfMeta || {},
    component: () => import("/vercel/path0/pages/painel/detalhes-investimento/[operationId].vue")
  },
  {
    name: "painel",
    path: "/painel",
    meta: index4zvfyrDpQuMeta || {},
    component: () => import("/vercel/path0/pages/painel/index.vue")
  },
  {
    name: "painel-indicacao",
    path: "/painel/indicacao",
    meta: indexXyMqzEaGQPMeta || {},
    component: () => import("/vercel/path0/pages/painel/indicacao/index.vue")
  },
  {
    name: "painel-ir",
    path: "/painel/ir",
    meta: indexjriJGez7d4Meta || {},
    component: () => import("/vercel/path0/pages/painel/ir/index.vue")
  },
  {
    name: "painel-pendentes",
    path: "/painel/pendentes",
    meta: indexoRFjcRdhR3Meta || {},
    component: () => import("/vercel/path0/pages/painel/pendentes/index.vue")
  },
  {
    name: "painel-perfil-alterar-email",
    path: "/painel/perfil/alterar-email",
    meta: index4ge3cW3RvRMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-email/index.vue")
  },
  {
    name: "painel-perfil-alterar-senha",
    path: "/painel/perfil/alterar-senha",
    meta: indextJn3HW0S6ZMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-senha/index.vue")
  },
  {
    name: "painel-perfil-alterar-telefone",
    path: "/painel/perfil/alterar-telefone",
    meta: index51TLsa3RqnMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-telefone/index.vue")
  },
  {
    name: "painel-perfil-chave-pix-adicionar",
    path: "/painel/perfil/chave-pix/adicionar",
    meta: adicionar5DbZF45VAkMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/chave-pix/adicionar.vue")
  },
  {
    name: "painel-perfil-chave-pix",
    path: "/painel/perfil/chave-pix",
    meta: indexcNoZLf9kikMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/chave-pix/index.vue")
  },
  {
    name: "EditBankAccount",
    path: "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editarBlm77FzKTnMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/[id]/editar.vue")
  },
  {
    name: "painel-perfil-dados-bancarios-adicionar",
    path: "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionarv4gx5QDWNTMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/adicionar.vue")
  },
  {
    name: "painel-perfil-dados-bancarios",
    path: "/painel/perfil/dados-bancarios",
    meta: indexSS8G5cbybdMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/index.vue")
  },
  {
    name: "painel-perfil-dados-patrimoniais",
    path: "/painel/perfil/dados-patrimoniais",
    meta: index6PTCbwI1a2Meta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/dados-patrimoniais/index.vue")
  },
  {
    name: "painel-perfil-dados-pessoais",
    path: "/painel/perfil/dados-pessoais",
    meta: index0iybtMw4bpMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/dados-pessoais/index.vue")
  },
  {
    name: "painel-perfil-desativar-conta",
    path: "/painel/perfil/desativar-conta",
    meta: indexk4WHrAo1f1Meta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/desativar-conta/index.vue")
  },
  {
    name: "painel-perfil-documentos",
    path: "/painel/perfil/documentos",
    meta: index9DLGyTJwaDMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/documentos/index.vue")
  },
  {
    name: "painel-perfil-endereco",
    path: "/painel/perfil/endereco",
    meta: indexOCaA4S2QoKMeta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/endereco/index.vue")
  },
  {
    name: "painel-perfil",
    path: "/painel/perfil",
    meta: indexnjmxEyuke3Meta || {},
    component: () => import("/vercel/path0/pages/painel/perfil/index.vue")
  },
  {
    name: "painel-wallet-assetSymbol",
    path: "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93GUBCS5HFzHMeta || {},
    component: () => import("/vercel/path0/pages/painel/wallet/[assetSymbol].vue")
  },
  {
    name: "painel-wallet-deposito",
    path: "/painel/wallet/deposito",
    meta: depositoVEnQ0vDnLTMeta || {},
    component: () => import("/vercel/path0/pages/painel/wallet/deposito.vue")
  },
  {
    name: "painel-wallet",
    path: "/painel/wallet",
    meta: indexkGK62At1OMMeta || {},
    component: () => import("/vercel/path0/pages/painel/wallet/index.vue")
  },
  {
    name: "painel-wallet-resgate",
    path: "/painel/wallet/resgate",
    meta: resgateuqCnhvWZRDMeta || {},
    component: () => import("/vercel/path0/pages/painel/wallet/resgate.vue")
  },
  {
    name: "parceiros",
    path: "/parceiros",
    component: () => import("/vercel/path0/pages/parceiros/index.vue")
  },
  {
    name: "politica-de-privacidade",
    path: "/politica-de-privacidade",
    component: () => import("/vercel/path0/pages/politica-de-privacidade/index.vue")
  },
  {
    name: "quem-somos",
    path: "/quem-somos",
    component: () => import("/vercel/path0/pages/quem-somos/index.vue")
  },
  {
    name: "remocao-de-conta",
    path: "/remocao-de-conta",
    component: () => import("/vercel/path0/pages/remocao-de-conta/index.vue")
  },
  {
    name: "seja-investidor-signupCode",
    path: "/seja-investidor/:signupCode()",
    meta: _91signupCode_93WVrBrc7ffnMeta || {},
    component: () => import("/vercel/path0/pages/seja-investidor/[signupCode].vue")
  },
  {
    name: "termos-de-uso",
    path: "/termos-de-uso",
    component: () => import("/vercel/path0/pages/termos-de-uso/index.vue")
  },
  {
    name: "tokenizacao",
    path: "/tokenizacao",
    component: () => import("/vercel/path0/pages/tokenizacao/index.vue")
  }
]