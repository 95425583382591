export const useLogSentryInfo = () => {
  const route = useRoute();
  const accountCookie = useRawCookie("account");
  const refreshExpCookie = useRawCookie("refresh_exp");
  const refreshTokenCookie = useRawCookie("refresh_token");
  const tokenCookie = useRawCookie("token");
  const tokenExpCookie = useRawCookie("token_exp");
  const nidCookie = useRawCookie("nid");

  return () => {
    console.log("SENTRY__USER_ID", accountCookie.value);
    console.log("SENTRY__ROUTE", route.fullPath);
    console.log("SENTRY__REFRESH_EXP", refreshExpCookie.value);
    console.log("SENTRY__T_EXP", tokenExpCookie.value);
    console.log("SENTRY__HAS_REFRSEH", !!refreshTokenCookie.value);
    console.log("SENTRY__HAS_T", !!tokenCookie.value);
    console.log("SENTRY__HAS_NID", !!nidCookie.value);
  };
};
