import { FetchError } from "ofetch";

export class HurstError extends Error {
  constructor(name: string) {
    super(name);
    this.name = name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class UnauthenticatedError extends HurstError {
  constructor() {
    super("UnathenticatedError");
  }
}

export class UnauthorizedError extends HurstError {
  constructor() {
    super("UnauthorizedError");
  }
}

export class EmailAlreadyExistsError extends HurstError {
  constructor() {
    super("EmailAlreadyExistsError");
  }
}

export class PhoneAlreadyExistsError extends HurstError {
  constructor() {
    super("PhoneAlreadyExistsError");
  }
}

export class NidAlreadyExistsError extends HurstError {
  constructor() {
    super("NidAlreadyExistsError");
  }
}

export class NidInvalidError extends HurstError {
  constructor() {
    super("NidInvalidError");
  }
}

export class AccountNotConfirmedError extends HurstError {
  constructor() {
    super("AccountNotConfirmedError");
  }
}

export class EquityNotQualifiedError extends HurstError {
  constructor() {
    super("EquityNotQualifiedError");
  }
}

export class EquityInvalidError extends HurstError {
  constructor() {
    super("EquityInvalidError");
  }
}

export class EquityExpiredError extends HurstError {
  constructor() {
    super("EquityExpiredError");
  }
}

export class IRNotProcesssError extends HurstError {
  constructor() {
    super("IRNotProcesssError");
  }
}

export class UserHasTransactionsError extends HurstError {
  constructor() {
    super("UserHasTransactionsError");
  }
}

export class TransactionNotValidError extends HurstError {
  constructor() {
    super("TransactionNotValidError");
  }
}

export const getDomainError = (error: any) => {
  let body: any;

  if (error instanceof FetchError) {
    body = error.data;
  }

  if (body?.data?.message === "User not Authorized") {
    return new UnauthenticatedError();
  }

  switch (body?.data?.code ?? body?.error) {
    case "NotAuthorizedException":
      return new UnauthorizedError();
    case "EmailAlreadyExists":
      return new EmailAlreadyExistsError();
    case "PhoneAlreadyExists":
      return new PhoneAlreadyExistsError();
    case "NidAlreadyExists":
    case "HCP__EXISTS_NID":
      return new NidAlreadyExistsError();
    case "HCP__REQUIRED_NID":
      return new NidInvalidError();
    case "HCP__ACCOUNT_NOT_CONFIRMED":
      return new AccountNotConfirmedError();
    case "AccountNotQualifiedToInvest":
      return new EquityNotQualifiedError();
    case "AccountNotQualifiedToInvestMoreThantOneYearFromLastVerification":
      return new EquityExpiredError();
    case "AccountNotQualifiedToInvestInThisEquityCategory":
      return new EquityInvalidError();
    case "TransactionNotValid":
      return new TransactionNotValidError();
    case "IRNotProcess":
      return new IRNotProcesssError();
    case "UserHasTransactions":
      return new UserHasTransactionsError();
    default:
      return new Error("Unexpected Error");
  }
};
