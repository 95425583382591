export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const VueSentry = await import("@sentry/vue");
    const vueApp = nuxtApp.vueApp;
    const router = useRouter();
    const config = useRuntimeConfig();

    if (!config.public.SENTRY_DSN_PUBLIC) {
      return;
    }

    VueSentry.init({
      app: vueApp,
      dsn: (config.public.SENTRY_DSN_PUBLIC as string) ?? undefined,
      integrations: [
        VueSentry.browserTracingIntegration({ router }),
        VueSentry.replayIntegration(),
        VueSentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
      replaysSessionSampleRate: config.public
        .SENTRY_REPLAY_SAMPLE_RATE as number,
      replaysOnErrorSampleRate: config.public
        .SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
    });
  },
});
