import dayjs from "dayjs";

export default defineNuxtRouteMiddleware(({ fullPath }) => {
  const signOut = useSignOut();

  if (
    !fullPath.startsWith("/painel") &&
    !fullPath.startsWith("/completar-cadastro")
  ) {
    return;
  }

  const token = useCookie("token").value;
  const refreshToken = useCookie("refresh_token").value;
  const refreshTokenExpTimestamp = useCookie<number>("refresh_exp").value;

  if (
    !token ||
    !refreshToken ||
    !refreshTokenExpTimestamp ||
    dayjs().isAfter(refreshTokenExpTimestamp)
  ) {
    return signOut();
  }
});
